import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Pagination } from 'react-bootstrap';


class Crosshole extends Component {
    
    render () {
        return (

            <div>
                <div className="row">
                    <div className="col-lg-8 col-sm-6">
                    <div className="p-4" style={{backgroundColor: "#f2f2f2"}}>
                        <Carousel>
                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/crosshole00.png')} fluid/>                                
                            </Carousel.Item>

                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/crosshole01.png')} />                                
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">

                        <nav className="mt-3">
                            <ul className="pagination">

                                <Pagination.Prev className="mr-auto" href="parallel" />
                                <h4 className="d-flex justify-content-center align-self-center" align="center">Cross-hole Sonic Logging Test</h4>
                                <Pagination.Next className="ml-auto" href="settlement" />
                           
                            </ul>
                        </nav>
                        <hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>

                        <div className="mt-4 mr-4 text-justify" style={{"lineHeight": "180%"}}>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        Cross-hole Sonic Logging Test เป็นวิธีการตรวจสอบความสมบูรณ์ของเสาเข็มอีกวิธีหนึ่ง ใช้วิธีการฝังท่อลงในตัวเสาเข็มก่อนที่จะเทคอนกรีต
                        เหมาะสำหรับใช้ตรวจสอบความสมบูรณ์ของเสาเข็มเจาะ

                        </div>

                        <div className="mt-4 mr-4 text-justify" style={{"lineHeight": "220%"}}>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        การติดตั้งท่อเพื่อใช้ในการตรวจสอบสามารถทำได้โดย ติดตั้งท่อเหล็กปลายปิดโดยผูกติด ลงไปกับเหล็กเสริมของเสาเข็ม จำนวนท่อที่ใช้จะพิจารณาจากขนาดเส้นผ่านศูนย์กลางของเสาเข็มทดสอบ จัดเรียงท่อให้อยู่บนเส้นรอบวงของเสาเข็ม
                        และมีระยะห่างเท่าๆกัน ฝังเหล็กเสริมและท่อลงในหลุมเสาเข็ม จากนั้นจึงเทคอนกรีตลงในเสาเข็มต้นนั้น

                        </div>

                        <div className="mt-4 mr-4 text-justify" style={{"lineHeight": "220%"}}>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        หลังจากทิ้งไว้ให้คอนกรีตแข็งตัวในระยะเวลาหนึ่งแล้ว จึงเริ่มต้นการตรวจสอบด้วยการเติมน้ำให้เต็มท่อเพื่อเป็นตัวกลางในการส่งสัญญาณ 
                        จากนั้นหย่อนหัวส่งสัญญาณ (Transmitter) และหัวรับสัญญาณ (Receiver) ลงในท่อที่ความลึกเท่ากันโดยเริ่มต้นจากปลายเสาเข็ม 
                        หัวส่งสัญญาณจะทำหน้าที่ส่งคลื่นเสียง Ultrasonic ซึ่งมีความถี่มากกว่า 20,000 Hz ไปที่หัวรับสัญญาณ จากนั้นเครื่องจะทำการประมวลผล
                        เพื่อหาความเร็วที่คลื่นเสียงวิ่งผ่านเสาเข็ม สำหรับคอนกรีตที่มีความบกพร่องจะมีความเร็วที่ต่ำกว่าบริเวณอื่น จากนั้นค่อยๆ ดึงหัวส่งสัญญาณและ
                        หัวรับสัญญาณขึ้นจนกระทั่งถึงหัวเสาเข็ม เครื่องประมวลผลสัญญาณจะทำการบันทึกสัญญาณและสร้างแผนภาพ “Waterfall diagram” 
                        เพื่อระบุความสมบูรณ์ของเสาเข็มตั้งแต่บริเวณหัวเสาเข็มจนถึงปลายเสาเข็ม

                        </div>

                    </div>
                </div>
            </div>


        	
        )
    }
}

export default Crosshole;