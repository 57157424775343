import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Pagination } from 'react-bootstrap';


class Parallel extends Component {
    
    render () {
        return (


            <div>
                <div className="row">
                    <div className="col-lg-8 col-sm-6">
                    <div className="p-4" style={{backgroundColor: "#f2f2f2"}}>
                        <Carousel>
                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/parallel00.png')} fluid/>                                
                            </Carousel.Item>

                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/parallel01.png')} />                                
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">

                        <nav className="mt-3">
                            <ul className="pagination">

                                <Pagination.Prev className="mr-auto" href="seismic" />
                                <h4 className="d-flex justify-content-center align-self-center" align="center">Parallel Seismic Test</h4>
                                <Pagination.Next className="ml-auto" href="crosshole" />
                           
                            </ul>
                        </nav>
                        <hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>

                        <div className="mt-4 mr-4 text-justify" style={{"lineHeight": "220%"}}>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        การตรวจหาความยาวเสาเข็มด้วยวิธีนี้จะใช้วิธีตรวจจับคลื่นความสั่นสะเทือนที่เกิดจากการใช้ค้อนเคาะที่ฐานราก 
                        คลื่นความสั่นสะเทือนจะเคลื่อนผ่านตัวเสาเข็มไปสู่ตัวรับสัญญาณที่หย่อนลงไปในท่อที่เตรียมไว้ 
                        ตัวรับสัญญาณจะถูกหย่อนลงไปในท่อลึกลงไปตามลำดับ เมื่อใดที่ตัวรับสัญญาณหย่อนลงเลยความยาวเสาเข็ม 
                        จะทำให้ไม่สามารถรับสัญญาณจากเสาเข็มได้ จึงสามารถประเมินได้ว่าปลายเสาเข็มอยู่ที่ระดับความลึกเท่าใด

                        </div>

                    </div>
                </div>
            </div>


        )
    }
}

export default Parallel;