import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Pagination } from 'react-bootstrap';


class Seismic extends Component {
    
    render () {
        return (


            <div>
                <div className="row">
                    <div className="col-lg-8 col-sm-6">
                    <div className="p-4" style={{backgroundColor: "#f2f2f2"}}>
                        <Carousel>
                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/seismic00.png')} fluid/>                                
                            </Carousel.Item>

                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/seismic01.png')} />                                
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">

                        <nav className="mt-3">
                            <ul className="pagination">

                                <Pagination.Prev className="mr-auto" href="slab" />
                                <h4 className="d-flex justify-content-center align-self-center" align="center">Seismic Test</h4>
                                <Pagination.Next className="ml-auto" href="parallel" />
                           
                            </ul>
                        </nav>
                        <hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>

                        <div className="mt-4 mr-4 text-justify" style={{"lineHeight": "220%"}}>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        Seismic test เป็นการทดสอบความสมบูรณ์ของเสาเข็มโดยการใช้ค้อนเคาะลงบริเวณหัวเสาเข็ม การเคาะจะทำให้เกิดคลื่นความเค้นอัด
                        (Compression stress wave) ผ่านลงไปในเสาเข็มและจะสะท้อนกลับมาตามลำดับเป็นคลื่นความเค้นดึง (Tension stress wave) 
                        ตัวรับสัญญาณ (Accelerometer) จะรับสัญญาณและส่งผ่านไปยังเครื่องบันทึกข้อมูล (PIT Collector) 
                        หากมีความบกพร่องในตัวเสาเข็มคลื่นเสียงจะส่งสัญญาณแสดงความผิดปกติในรูปของกราฟที่ผิดแปลกไป 
                        ผลการทดสอบที่ได้จะนำมาวิเคราะห์หาความบกพร่องในตัวเสาเข็ม และความยาวโดยประมาณของเข็มต่อไป

                        </div>

                    </div>
                </div>
            </div>


        )
    }
}

export default Seismic;