import React, { Component } from 'react';
import { Image } from 'react-bootstrap';


class Contact extends Component {

	render () {
		return (

			<div className="container margin-top mw-100 p-5 bg-white rounded fluid">

				<div className="row">

					<div className="col-lg-6 col-sm-6">
						<iframe 
							title="finessesoil map"
					    	src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1152.8151916396066!2d100.49586157066561!3d13.621673136462249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2a3a2a106ed41%3A0xd39df7c85092b9e9!2z4Lif4Li14LmA4LiZ4Liq4Liq4LmMIOC4i-C4reC4ouC4peC5jCDguYDguJfguKrguJXguLTguYnguIcg4LiI4Liz4LiB4Lix4LiU!5e0!3m2!1sth!2sth!4v1599537803255!5m2!1sth!2sth" 
					    	width="100%" 
					    	height="450" 
					    	frameBorder="0"
					    	align="center"
					    />
					</div>

					<div className="col-lg-6 col-sm-6 container mw-100 p-5 bg-white shadow-lg rounded">

						<h3>บริษัท ฟีเนสส์ ซอยล์ เทสติ้ง จำกัด</h3>

						<div style={{"lineHeight": "200%"}}>
							23/246 ซอยประชาอุทิศ 76 แยก 4/2 แขวงทุ่งครุ เขตทุ่งครุ กทม. 10140
						</div>

						<div style={{"lineHeight": "200%"}}>
							โทร 028739305-6
						</div>

						<div className="row mt-5">
							<div className="col-2">
								<Image style={{"width": "70px", "height": "auto"}} src={require('../../img/Icon/gmail-icon.png')} />                                
							</div>
							<div className="col-10 pl-5 justify-content-center align-self-center">
								finessesoil@gmail.com
							</div>
						</div>

						<div className="row mt-5">
							<div className="col-2">
								<Image style={{"width": "70px", "height": "auto"}} src={require('../../img/Icon/line_icon.png')} />                                
							</div>
							<div className="col-10 pl-5 justify-content-center align-self-center">
								ID: 8739305
							</div>
						</div>

						<div className="row mt-5">
							<Image style={{"width": "100px", "height": "auto"}} src={require('../../img/qr_finsoil_line.jpg')} />
						</div>

						   

					</div>
				</div>

			    


			    

		
			</div>
	  	);
	}
}

export default Contact;