import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Pagination } from 'react-bootstrap';


class Pundit extends Component {
    
    render () {
        return (


            <div>
                <div className="row">
                    <div className="col-lg-8 col-sm-6">
                    <div className="p-4" style={{backgroundColor: "#f2f2f2"}}>
                        <Carousel>
                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/pundit00.png')} fluid/>                                
                            </Carousel.Item>

                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/pundit01.png')} fluid/> 
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">

                        <nav className="mt-3">
                            <ul className="pagination">

                                <Pagination.Prev className="mr-auto" href="hammer" />
                                <h4 className="d-flex justify-content-center align-self-center" align="center">Pundit Test</h4>
                                <Pagination.Next className="ml-auto" href="coring" />
                           
                            </ul>
                        </nav>
                        <hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>

                        <div className="mt-4 mr-4 mb-5 text-justify" style={{"lineHeight": "180%"}}>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        การตรวจสอบคุณภาพของคอนกรีตด้วยวิธี Portable Ultrasonic Non Destructive Integrity Tester หรือ
                        Pundit Test เป็นการตรวจสอบคอนกรีตแบบไม่ทำลาย ตามมาตรฐาน ASTM 597-83 โดยการใช้คลื่นเสียงเดินทางผ่านเนื้อคอนกรีต
                        เพื่อตรวจดูคุณภาพของเนื้อคอนกรีตว่าอยู่ในสภาพดีหรือไม่อย่างไร ควรทำการตรวจสอบควบคู่กับ Rebound hammer Test
                        เนื่องจาก Rebound Hammer Test เป็นการตรวจสอบความแข็งที่พื้นผิว (Hardening Surface Test) 
                        ผลที่ได้จึงเป็นกําลังรับแรงอัดของคอนกรีตเฉพาะผิวด้านนอกของโครงสร้างเท่านั้น หากคอนกรีตที่ผิวด้านนอกเกิดปฏิกิริยาคาร์บอเนชั่นมาเป็นเวลานานจะทําให้คอนกรีตที่ผิวนอกแข็งกระด้าง 
                        กําลังรับแรงอัดของคอนกรีตที่ตรวจสอบได้จะแตกต่างจากกําลังรับแรงอัดของคอนกรีตส่วนที่อยู่ภายในโครงสร้างมาก 
                        การตรวจสอบ Pundit Test ทําให้ทราบว่าเนื้อคอนกรีตมีความต่อเนื่องเป็นอย่างไร
                        และได้ค่ากําลังรับแรงอัดของคอนกรีตโดยประมาณ แต่กําลังรับแรงอัดของคอนกรีตที่ได้จากการตรวจสอบด้วยวิธีนี้จะไม่นํามาใช้ในการพิจารณาโดยตรง
                        เพราะยังมีปัจจัยอื่นที่มีผลในการพิจารณาด้วย

                        </div>

                    </div>
                </div>
            </div>


        )
    }
}

export default Pundit;