import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { Navbar, Nav } from 'react-bootstrap';
import { Image } from 'react-bootstrap';


class NavBar extends Component {
    
    render () {
        return (
            <Navbar expand="lg">
			  <Navbar.Toggle aria-controls="basic-navbar-nav" />
			  <Navbar.Collapse id="basic-navbar-nav">
			    <Nav className="mr-auto">
			      <Nav.Link href="home">หน้าแรก</Nav.Link>

			      <li className="nav-item dropdown">
			        <a className="nav-link dropdown-toggle" href="home" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			          	งานตรวจสอบ
			        </a>
			        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">

			          <div className="dropdown-item" style={{backgroundColor: "#cdcdcd"}}>งานตรวจสอบโครงสร้าง</div>
			          <a className="dropdown-item" href="visual">Visual inspection</a>
			          <a className="dropdown-item" href="hammer">Rebound Hammer</a>
			          <a className="dropdown-item" href="pundit">PUNDIT Test</a>
			          <a className="dropdown-item" href="coring">Coring Test</a>
			          <a className="dropdown-item" href="ferroscan">Ferroscan</a>
			          <a className="dropdown-item" href="carbonation">Carbonation Depth Test</a>
			          <a className="dropdown-item" href="halfcell">Half-cell Potential</a>
			          <a className="dropdown-item" href="slab">Slab Load Test</a>

			          <div className="dropdown-item" style={{backgroundColor: "#cdcdcd"}}>งานตรวจสอบฐานราก</div>
			          <a className="dropdown-item" href="seismic">Seismic Test</a>
			          <a className="dropdown-item" href="parallel">Parallel Seismic</a>
			          <a className="dropdown-item" href="crosshole">Cross-hole Sonic Logging Test</a>
			          <a className="dropdown-item" href="settlement">Settlement survey</a>

			        </div>
			      </li>

			      <li className="nav-item dropdown">
			        <a className="nav-link dropdown-toggle" href="home" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			        	งานเสริมโครงสร้างและฐานราก
			        </a>
			        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">

			          <a className="dropdown-item" href="cfrp">เสริมกำลังด้วยเหล็กรูปพรรณ หรือ CFRP</a>
			          <a className="dropdown-item" href="repair">ซ่อมโครงสร้างเนื่องจากเหล็กเสริมเป็นสนิม</a>
			          <a className="dropdown-item" href="underpinning">เสริมฐานรากอาคาร (Underpinning)</a>

			        </div>
			      </li>
			      
			      <Nav.Link href="liftandmove">ยกและย้ายอาคาร</Nav.Link>
			      <Nav.Link href="soil">เจาะสำรวจดิน</Nav.Link>
			      <Nav.Link href="topo">สำรวจพื้นที่</Nav.Link>
			      <Nav.Link href="contact">ติดต่อเรา</Nav.Link>
			    </Nav>
			    <Form inline>
			      <Image style={{height:'35px',width:"auto"}} src={require('../../img/Logo/Logo_Finesse.png')} rounded />			        		
			    </Form>
			  </Navbar.Collapse>
			</Navbar>
			
        )
    }
}

export default NavBar;