import React, { Component } from 'react';

import './CompanyDetail.css';


class CompanyDetail extends Component {
    
    render () {
        return (

        	<div className="container margin-top shadow-lg mw-100 p-5 bg-white rounded">

	        	<h2>บริษัท ฟีเนสส์ ซอยล์ <div className="rwd-break" />เทสติ้ง จำกัด</h2>

	        	<div className="margin-top mw-100 mt-3 mb-3">

	        	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

	        	ก่อตั้งในปี พ.ศ. 2534 ให้บริการด้านงานแก้ไขอาคารทรุด ยกอาคาร ย้ายอาคาร ตรวจสอบแก้ไขโครงสร้างและฐานรากของอาคาร 
	        	โดยทีมวิศวกรที่มีความเชี่ยวชาญ มีผลงานต่างๆมากมาย อาทิเช่น งานยกอาคารสุนันทาลัย โรงเรียนราชินี อุโบสถวัดชัยพฤกมาลาราชวรวิหาร 
	        	อุโบสถวัดเกาะพญาเจ่ง อุโบสถวัดแก้วฟ้า อาคารอำนวยการโรงสุราบางยี่ขัน อาคารโบราณสถานบ้านมะลิวัลย์ พระตำหนักพระเจ้าบรมวงศ์เธอ 
	        	พระองค์เจ้าอัปภันตรีปชาฯ มหาวิทยาลัยสวนดุสิต ผลงานการย้ายอาคาร เช่น หอสมุดดำรงราชานุภาพ วัดมหาธาตุยุวราชรังสฤษฎิ์ราชวรมหาวิหาร
	        	อุโบสถวัดโตนดเตี้ย อาคาร Mockup และสำนักงานขายโครงการ โนเบิล บี เทอร์ตี้ทรี ผลงานการเสริมและตัดโครงสร้าง เช่น 
	        	งานเสริมโครงสร้างและตัดคาน โรงพยาบาลวิภาราม นวมินทร์

	        	</div>

	        </div>

        )
    }
}

export default CompanyDetail;