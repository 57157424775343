import React, { Component } from 'react';
import { Image } from 'react-bootstrap';


class JobList extends Component {

    render () {

        return (

	        <div className="container margin-top mw-100 p-5 bg-white rounded fluid" >

	        	<h2 className="mb-3">ขอบเขตงาน</h2>
	        	<hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>

	        	<div>
	                <div className="row mb-3">
	                    <div className="col-lg-3 col-sm-6 mb-3">
	                        <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/JobList/survey.jpg')} rounded fluid/> 
	                    </div>
	                    <div className="col-lg-3 col-sm-6">

	                    	<h4>งานสำรวจ</h4>
		        			งานสำรวจพื้นที่และภูมิประเทศ (Topographic Survey) มี 2 รูปแบบ คือ สำรวจด้วยกล้อง Total Station
		        			และสำรวจด้วยระบบดาวเทียม (GPS)

	                    </div>

	                    <div className="col-lg-3 col-sm-6 mb-3">
	                        <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/JobList/pundit.jpg')} rounded fluid/> 
	                    </div>
	                    <div className="col-lg-3 col-sm-6 mb-3">

	                    	<h4>งานตรวจสอบ</h4>
			        		ตรวจสอบโครงสร้างของอาคารเพื่อนำข้อมูลมาใช้ในการออกแบบปรับปรุงโครงสร้าง

	                    </div>
	                </div>


	                <div className="row">
	                    <div className="col-lg-3 col-sm-6 mb-3">
	                        <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/JobList/repair.png')} rounded fluid/> 
	                    </div>
	                    <div className="col-lg-3 col-sm-6 mb-3">

	                    	<h4>เสริมโครงสร้างและฐานราก</h4>
	                    	เสริมฐานรากด้วยเสาเข็ม (Underpinning) และเสริมกำลังของโครงสร้าง เสา คาน ด้วยเหล็กรูปพรรณ 
	                    	หรือ Carbon-Fiber Reinforce Polymer (CFRP) รวมทั้งซ่อมแซมสนิมในเหล็กเสริม

	                    </div>

	                    <div className="col-lg-3 col-sm-6 mb-3">
	                        <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/JobList/lift_01.jpg')} rounded fluid/> 
	                    </div>
	                    <div className="col-lg-3 col-sm-6 mb-3">

	                    	<h4>งานยกและย้ายอาคาร</h4>
			        		ยกอาคาร ย้ายอาคาร ทั้งที่มีโครงสร้างเป็นผนังอิฐก่อ โครงสร้างไม้ และคอนกรีตเสริมเหล็ก

	                    </div>
	                </div>


	            </div>   
	        </div>

        )
    }
}

export default JobList;