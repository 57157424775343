import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Pagination } from 'react-bootstrap';


class Ferroscan extends Component {
    
    render () {
        return (


            <div>
                <div className="row">
                    <div className="col-lg-8 col-sm-6">
                    <div className="p-4" style={{backgroundColor: "#f2f2f2"}}>
                        <Carousel>
                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/ferroscan00.png')} fluid/>                                
                            </Carousel.Item>

                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/ferroscan02.png')} fluid/> 
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">

                        <nav className="mt-3">
                            <ul className="pagination">

                                <Pagination.Prev className="mr-auto" href="coring" />
                                <h4 className="d-flex justify-content-center align-self-center" align="center">Ferroscan</h4>
                                <Pagination.Next className="ml-auto" href="carbonation" />
                           
                            </ul>
                        </nav>
                        <hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>

                        <div className="mt-4 mr-4 mb-5 text-justify" style={{"lineHeight": "200%"}}>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        การตรวจสอบด้วยเครื่องมือ Ferroscan เป็นการตรวจสอบเพื่อหาตำแหน่ง ขนาด (ขึ้นอยู่กับระยะหุ้มของคอนกรีต)
                        และจำนวนเหล็กเสริม (เฉพาะเหล็กเสริมชั้นเดียว) ของโครงสร้าง การตรวจสอบวิธีนี้เป็นการตรวจสอบแบบไม่ทำลาย
                        (Non-destructive Test) และไม่ทำให้โครงสร้างเสียหาย ทำการตรวจสอบได้สะดวก และรวดเร็ว
                        ได้ผลการตรวจสอบค่อนข้างชัดเจน โดยเฉพาะตำแหน่งและจำนวนของเหล็กเสริม (เฉพาะเหล็กเสริมชั้นเดียว) 
                        เนื่องจากสามารถดูผลการตรวจสอบจากมอนิเตอร์ (Monitor) ได้ทันที

                        </div>

                    </div>
                </div>
            </div>


        )
    }
}

export default Ferroscan;