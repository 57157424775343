import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Image } from 'react-bootstrap';


class Topo extends Component {
    
    render () {
        return (


            <div>
                <div className="row">
                    <div className="col-lg-8 col-sm-6">
                    <div className="p-4" style={{backgroundColor: "#f2f2f2"}}>
                        <Carousel>
                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/topo00.png')} fluid/>                                
                            </Carousel.Item>

                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/topo01.png')} />                                
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">

                        <nav className="mt-3">
                            <ul className="pagination">

                                <h4>สำรวจพื้นที่ (Topographic Survey)</h4>
                           
                            </ul>
                        </nav>
                        <hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>

                        <div className="mt-4 mr-4 text-justify" style={{"lineHeight": "200%"}}>

                        &nbsp;&nbsp;

                        การสำรวจพื้นที่เป็นการคำนวณหาตำแหน่งบนผิวโลก เพื่อเก็บค่า 3 รูปแบบ คือ ระยะทาง (Distance), 
                        ทิศทาง (Direction) และ ระดับความสูง (Elevation) เมื่อนำค่าต่างๆมาเปรียบเทียบกับจุดอ้างอิง 
                        จะทำให้สามารถเขียนแผนที่ของพื้นที่ที่ทำการสำรวจได้ การสำรวจมี 2 แบบ คือ สำรวจด้วยกล้อง 
                        Total station และสำรวจด้วยระบบจานดาวเทียม GPS ทั้งนี้ขึ้นอยู่กับความเหมาะสมของพื้นที่หน้างาน

                        </div>

                    </div>
                </div>
            </div>


        )
    }
}

export default Topo;