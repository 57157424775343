import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Pagination } from 'react-bootstrap';


class Slab extends Component {
    
    render () {
        return (

            <div>
                <div className="row">
                    <div className="col-lg-8 col-sm-6">
                    <div className="p-4" style={{backgroundColor: "#f2f2f2"}}>
                        <Carousel>
                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/slab00.png')} fluid/>                                
                            </Carousel.Item>

                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/slab01.png')} />                                
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">

                        <nav className="mt-3">
                            <ul className="pagination">

                                <Pagination.Prev className="mr-auto" href="halfcell" />
                                <h4 className="d-flex justify-content-center align-self-center" align="center">Slab Load Test</h4>
                                <Pagination.Next className="ml-auto" href="seismic" />
                           
                            </ul>
                        </nav>
                        <hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>


                        <div className="mt-4 mr-4 mb-5 text-justify" style={{"lineHeight": "180%"}}>

                        &nbsp;&nbsp;

                        การทดสอบพฤติกรรมการรับน้ำหนักบรรทุกของแผ่นพื้น เป็นการทดสอบว่าพื้นสามารถรับน้ำหนักบรรทุกจรตามเทศบัญญัติของกรุงเทพมหานคร
                        พ.ศ. 2522 ได้อย่างปลอดภัยหรือไม่ โดยในการทดสอบจะทำการวัดค่าการแอ่นตัวและคืนตัวของพื้นและคานตามมาตรฐาน ACI 318/319R 

                        </div>

                    </div>
                </div>
            </div>

        )
    }
}

export default Slab;