import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import './ContactFooter.css';


class ContactFooter extends Component {

    render () {

        return (

	        <div className="footer container margin-top shadow-lg mw-100 p-5 mt-3 rounded">

                <Image style={{height:'35px',width:"auto"}} src={require('../../img/Logo/Logo_Finesse.png')} rounded />
	        	
                <h4 className="mt-3">บริษัท ฟีเนสส์ ซอยล์ เทสติ้ง จำกัด</h4>

	        	<div className="margin-top mw-100 mt-3 mb-3">
                23/246 ซอยประชาอุทิศ 76 แยก 4/2 แขวงทุ่งครุ เขตทุ่งครุ กทม. 10140
                เบอร์โทร 028739305-6
	        	</div>

	        </div>

        )
    }
}

export default ContactFooter;