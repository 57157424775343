import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import NavBar from './components/NavBar/NavBar';
import Home from './components/Home/Home';

import Liftandmove from './components/Liftandmove/Liftandmove';
import Contact from './components/Contact/Contact';

import Topo from './components/Topo/Topo';

import Visual from './components/Visual/Visual';
import Slab from './components/Slab/Slab';
import Pundit from './components/Pundit/Pundit';
import Hammer from './components/Hammer/Hammer';
import Coring from './components/Coring/Coring';
import Ferroscan from './components/Ferroscan/Ferroscan';
import Carbonation from './components/Carbonation/Carbonation';
import Halfcell from './components/Halfcell/Halfcell';

import Settlement from './components/Settlement/Settlement';
import Seismic from './components/Seismic/Seismic';
import Parallel from './components/Parallel/Parallel';
import Crosshole from './components/Crosshole/Crosshole';

import Repair from './components/Repair/Repair';
import CFRP from './components/CFRP/CFRP';
import Underpinning from './components/Underpinning/Underpinning';

import Portfolio from './components/Portfolio/Portfolio';

import Soil from './components/Soil/Soil';


class App extends Component {
  render () {
    return (
      <div>
        <NavBar/>
        <Switch>

          <Route exact path="/home" component={Home} />
          
          <Route path="/liftandmove" component={Liftandmove} />
          <Route path="/contact" component={Contact} />

          <Route path="/topo" component={Topo} />

          <Route path="/visual" component={Visual} />
          <Route path="/hammer" component={Hammer} />
          <Route path="/pundit" component={Pundit} />
          <Route path="/coring" component={Coring} />
          <Route path="/ferroscan" component={Ferroscan} />
          <Route path="/carbonation" component={Carbonation} />
          <Route path="/halfcell" component={Halfcell} />
          <Route path="/slab" component={Slab} />

          <Route path="/settlement" component={Settlement} />
          <Route path="/seismic" component={Seismic} />
          <Route path="/parallel" component={Parallel} />
          <Route path="/crosshole" component={Crosshole} />

          <Route path="/repair" component={Repair} />
          <Route path="/cfrp" component={CFRP} />
          <Route path="/underpinning" component={Underpinning} />

          <Route path="/portfolio" component={Portfolio} />

          <Route path="/soil" component={Soil} />
          

          <Route exact path="/" component={Home} />

        </Switch>
      </div>
    );
  }
}

export default App;