import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Pagination } from 'react-bootstrap';


class Carbonation extends Component {
    
    render () {
        return (

            <div>
                <div className="row">
                    <div className="col-lg-8 col-sm-6">
                    <div className="p-4" style={{backgroundColor: "#f2f2f2"}}>
                        <Carousel>
                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/carbonation00.png')} fluid/>                                
                            </Carousel.Item>

                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/carbonation01.png')} fluid/> 
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">

                        <nav className="mt-3">
                            <ul className="pagination">

                                <Pagination.Prev className="mr-auto" href="ferroscan" />
                                <h4 className="d-flex justify-content-center align-self-center" align="center">Carbonation Depth Test</h4>
                                <Pagination.Next className="ml-auto" href="halfcell" />
                           
                            </ul>
                        </nav>
                        <hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>


                        <div className="mt-4 mr-4 mb-5 text-justify" style={{"lineHeight": "200%"}}>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        "โดยทั่วไปในการวิเคราะห์ความแข็งแรงของโครงสร้าง (Strength Evaluation) จะพิจารณาจากคุณสมบัติเชิงกลของคอนกรีตเป็นหลัก
                        ในกรณีของโครงสร้างคอนกรีตเสริมเหล็ก คุณสมบัติที่ใช้วิเคราะห์สามารถประเมินได้จากการตรวจสอบด้วยวิธี Schmidt Hammer Test,
                        Pundit Test, Coring Test หรือวิธีการอื่นใด แต่ความเป็นจริงในการประเมินความแข็งแรงของโครงสร้าง รวมทั้งอายุการใช้งานจนเสื่อมสภาพนั้น
                        สิ่งแวดล้อมของโครงสร้างแต่ละแห่งนั้นก็มีความสำคัญอย่างมาก จึงต้องมีการทดสอบทางเคมี เช่น Carbonation Depth Test และ Chloride Test
                        ประกอบไปด้วยกัน : ACI Comittee 201 (Guide to Durable Concrete)" ซึ่งในการตรวจสอบความลึกการเกิดคาร์บอเนชั่นของคอนกรีต
                        จะใช้สว่านที่มีดอกเจาะขนาดเส้นผ่านศูนย์กลางไม่น้อยกว่า 10 มม. เจาะคอนกรีตโดยให้ผงของคอนกรีตตกลงบนกระดาษกรองที่ถูกพ่นด้วยสารละลายฟีนอล์ฟทาลีน
                        ระหว่างการเจาะให้หมุนกระดาษกรองเพื่อให้ผงคอนกรีตที่หล่นบนกระดาษกรองเปลี่ยนเป็นสีม่วง แล้วดำเนินการวัดความลึกของรูเจาะ ในการทดสอบ 1 ตำแหน่ง
                        ต้องตรวจสอบความลึกของการเกิดคาร์บอเนชั่นอย่างน้อย 3 รู แล้วทำการเฉลี่ยค่าที่ได้จากการตรวจสอบ

                        </div>

                    </div>
                </div>
            </div>


        )
    }
}

export default Carbonation;