import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Pagination } from 'react-bootstrap';

import "../pagination.css";


class Visual extends Component {
    
    render () {
        return (

            <div>
                <div className="row">
                    <div className="col-lg-8 col-sm-6">
                    <div className="p-4" style={{backgroundColor: "#f2f2f2"}}>
                        <Carousel>
                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/visual00.png')} fluid/>                                
                            </Carousel.Item>

                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/visual01.png')} />                                
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">

                        <nav className="mt-3">
                            <ul className="pagination">

                                <Pagination.Prev className="mr-auto" href="settlement" />
                                <h5 className="d-flex justify-content-center align-self-center" align="center">สำรวจความเสียหายทางกายภาพ<br></br>ด้วยการตรวจพินิจ (Visual Inspection)</h5>
                                <Pagination.Next className="ml-auto" href="hammer" />
                           
                            </ul>
                        </nav>
                        <hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>


                        <div className="mt-4 mr-4 mb-5 text-justify" style={{"lineHeight": "220%"}}>

                        &nbsp;&nbsp;

                        การสำรวจความเสียหายทางกายภาพด้วยการตรวจพินิจ (Visual Inspection) 
                        เป็นการสำรวจเก็บสภาพความเสียหายที่มีนัยสำคัญ บันทึกลงในแบบแปลนอาคาร เพื่อนำไปใช้ประกอบการประเมินความเสียหาย 
                        และหาแนวทางในการซ่อมแซมเสริมกำลังในลำดับต่อไป

                        </div>


                    </div>
                </div>
            </div>

        )
    }
}

export default Visual;