import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';

import './HomeCarousel.css';


class HomeCarousel extends Component {
    
    render () {
        return (
        	<Carousel>

			  <Carousel.Item>
			    <img
			      className="d-block w-100"
			      src={require('../../img/HomeCarousel/carousel04.png')}
			      alt="First slide"
			    />
			    <Carousel.Caption>
			    	<div className="carouseltext rounded">
			      		<h3 className="mx-3 my-3 carouselcaption" style={{"lineHeight": "180%"}}>
			      			<div>ยกและย้ายอาคารโบราณ</div>
			      			<div>เพื่อการอนุรักษ์ที่ยั่งยืน</div>
			      		</h3>
			      	</div>
			    </Carousel.Caption>
			  </Carousel.Item>

			  <Carousel.Item>
			    <img
			      className="d-block w-100"
			      src={require('../../img/HomeCarousel/carousel01.png')}
			      alt="Second slide"
			    />

			    <Carousel.Caption>
			      	<div className="carouseltext carouselcaption rounded">
			      		<h3 className="mx-3 my-3 carouselcaption" style={{"lineHeight": "180%"}}>
			      			<div style={{"fontSize": "100%"}}>ตรวจสอบโครงสร้าง และฐานราก</div>
			      		</h3>
			      	</div>
			    </Carousel.Caption>
			  </Carousel.Item>
		
			  <Carousel.Item>
			    <img
			      className="d-block w-100"
			      src={require('../../img/HomeCarousel/carousel08.png')}
			      alt="Third slide"
			    />

			    <Carousel.Caption>
			    	<div className="carouseltext carouselcaption rounded">
			    		<h3 className="mx-3 my-3 carouselcaption" style={{"lineHeight": "180%"}}>
			      			<div style={{"fontSize": "100%"}}>สำรวจการทรุดตัว</div>
			      		</h3>
			      	</div>
			    </Carousel.Caption>
			  </Carousel.Item>

			  <Carousel.Item>
			    <img
			      className="d-block w-100"
			      src={require('../../img/HomeCarousel/carousel10.png')}
			      alt="Third slide"
			    />

			    <Carousel.Caption>
			      	<div className="carouseltext carouselcaption rounded">
			      		<h3 className="mx-3 my-3 carouselcaption" style={{"lineHeight": "180%"}}>
			      			<div style={{"fontSize": "100%"}}>สำรวจพื้นที่ภูมิประเทศ</div>
			      		</h3>
			      	</div>
			    </Carousel.Caption>
			  </Carousel.Item>

			  <Carousel.Item>
			    <img
			      className="d-block w-100"
			      src={require('../../img/HomeCarousel/carousel09.png')}
			      alt="Third slide"
			    />

			    <Carousel.Caption>
			      	<div className="carouseltext carouselcaption rounded">
			      		<h3 className="mx-3 my-3 carouselcaption" style={{"lineHeight": "180%"}}>
			      			<div style={{"fontSize": "100%"}}>ซ่อมแซมโครงสร้าง</div>
			      			<div style={{"fontSize": "100%"}}>และฐานรากของอาคาร</div>
			      		</h3>
			      	</div>
			    </Carousel.Caption>
			  </Carousel.Item>

			</Carousel>
        )
    }
}

export default HomeCarousel;