import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Pagination } from 'react-bootstrap';


class Halfcell extends Component {
    
    render () {
        return (

            <div>
                <div className="row">
                    <div className="col-lg-8 col-sm-6">
                    <div className="p-4" style={{backgroundColor: "#f2f2f2"}}>
                        <Carousel>
                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/halfcell00.png')} fluid/>                                
                            </Carousel.Item>

                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/halfcell01.png')} fluid/> 
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">

                        <nav className="mt-3">
                            <ul className="pagination">

                                <Pagination.Prev className="mr-auto" href="carbonation" />
                                <h4 className="d-flex justify-content-center align-self-center" align="center">Half-cell Potential</h4>
                                <Pagination.Next className="ml-auto" href="slab" />
                           
                            </ul>
                        </nav>
                        <hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>


                        <div className="mt-4 mr-4 mb-5 text-justify" style={{"lineHeight": "200%"}}>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        การตรวจสอบ Half-cell Testing เป็นการตรวจสอบแนวโน้มการเกิดสนิมของเหล็กเสริม
                        โดยใช้เครื่องมือ Half-cell Potential Instrument ด้วยการวัดค่าความต่างศักย์ไฟฟ้าระหว่างเหล็กเสริม
                        และบริเวณผิวคอนกรีตเพื่อประเมินค่าการกัดกร่อน และสภาพของชั้นหุ้มเหล็กเสริมในช่วงเวลาที่ทำการตรวจสอบ
                        หากมีค่าความต่างศักย์มากจะบ่งบอกถึงความเสี่ยงสูงในการกัดกร่อนของเหล็กเสริม

                        </div>

                    </div>
                </div>
            </div>


        )
    }
}

export default Halfcell;