import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Image } from 'react-bootstrap';


class Slab extends Component {
    
    render () {
        return (


            <div>
                <div className="row">
                    <div className="col-lg-8 col-sm-6">
                    <div className="p-4" style={{backgroundColor: "#f2f2f2"}}>
                        <Carousel>
                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/soil00.png')} fluid/>                                
                            </Carousel.Item>

                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/soil01.png')} />                                
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">

                        <nav className="mt-3">

                            <h4>งานเจาะสำรวจดิน</h4>

                        </nav>
                        <hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>


                        <div className="mt-4 mr-4 mb-3 text-justify" style={{"lineHeight": "180%"}}>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        การเจาะสำรวจดินส่วนใหญ่จะใช้วิธี Wash Boring คือใช้แท่งเหล็กที่เป็นสว่านหรือตัวกระทุ้งกวนดิน และใช้น้ำเป็นตัวพาเอาดินขึ้นมาจากหลุมเจาะ
                        ขั้นตอนเจาะสำรวจดินจะเริ่มด้วยการตอกท่อเหล็กป้องกันดินพัง (Casing) ขนาดเส้นผ่านศูนย์กลางประมาณ 4 นิ้ว ยาวประมาณ
                        3-6 ม. นำแท่งเหล็กที่เป็นตัวกวนดินซึ่งต่ออยู่กับท่อส่ง (Rod) หย่อนลงไปกวนดิน ท่อส่งเป็นท่อกลวง ขณะกวนดินจะเปิดน้ำให้ไหลในท่อส่งออกไปที่ปลายล่างตลอดเวลา
                        การกวนดินจะกระทำอย่างต่อเนื่องจนได้ความลึกเพิ่มขึ้นตามลำดับ และจะเก็บดินตัวอย่างทุกๆช่วงความลึก 1.50 ม. การเก็บดินตัวอย่างจะดึงท่อส่ง
                        (Rod) ขึ้นมาเปลี่ยนปลายล่างเป็นกระบอกเก็บตัวอย่าง กระบอกเก็บตัวอย่างดินที่ใช้กันอยู่มีสองแบบคือ กระบอกเก็บตัวอย่างแบบผิวบาง
                        (Thin-wall tube) และกระบอกเก็บตัวอย่างแบบผ่า (Split spoon tube)

                        </div>

                        <div className="mt-3 mr-4 mb-3 text-justify" style={{"lineHeight": "180%"}}>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        หลังจากนำดินมาทดสอบในห้องปฏิบัติการแล้วจะสรุปผลเป็นรายงานการเจาะสำรวจดิน ซึ่งโดยทั่วไปรายงานจะประกอบด้วย

                        <Container className="mt-3">
                        <li>ตารางแสดงผลการทดสอบดินในห้องปฏิบัติการ (Summary of Test Result)</li>
                        <li>ภาพตัดแสดงชั้นดิน และค่าพารามิเตอร์ต่างๆเทียบกับความลึก (Boring Log)</li>
                        <li>ตารางแสดงกำลังรับน้ำหนักของเสาเข็มแต่ละชนิด</li>
                        </Container>

                        </div>

                    </div>
                </div>
            </div>


        )
    }
}

export default Slab;