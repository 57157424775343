import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Pagination } from 'react-bootstrap';


class Hammer extends Component {
    
    render () {
        return (


            <div>
                <div className="row">
                    <div className="col-lg-8 col-sm-6">
                    <div className="p-4" style={{backgroundColor: "#f2f2f2"}}>
                        <Carousel>
                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/coring00.png')} fluid/>                                
                            </Carousel.Item>

                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/coring01.png')} fluid/> 
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">

                        <nav className="mt-3">
                            <ul className="pagination">

                                <Pagination.Prev className="mr-auto" href="pundit" />
                                <h4 className="d-flex justify-content-center align-self-center" align="center">Coring Test</h4>
                                <Pagination.Next className="ml-auto" href="ferroscan" />
                           
                            </ul>
                        </nav>
                        <hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>

                        <div className="mt-4 mr-4 mb-5 text-justify" style={{"lineHeight": "200%"}}>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        การทดสอบกำลังรับแรงอัดของคอนกรีตด้วยวิธี Coring Test นี้เป็นวิธีการทดสอบแบบกึ่งทำลาย (Semi Destructive Test)
                        แต่เป็นวิธีการที่ให้ผลการทดสอบที่ถูกต้อง แม่นยำ เนื่องจากมีการเก็บตัวอย่างของโครงสร้างจากสภาพใช้งานจริง แล้วทำการทดสอบกำลังรับแรงอัดตามมาตรฐาน
                        ASTM C42 โดยสุ่มเลือกตำแหน่งในโครงสร้างเพื่อเจาะเก็บแก่นคอนกรีต และนำแก่นคอนกรีตนั้นไปทดสอบหากำลังรับแรงอัดของคอนกรีตในห้องปฏิบัติการ
                        นำค่าที่ได้จากการทดสอบ ไปหาความสัมพันธ์กับผลที่ได้จากการทดสอบด้วย Rebound Hammer Test ที่ตำแหน่งเดียวกัน เพื่อหาค่า Strength relationship แล้วนำค่าดังกล่าวไปใช้กับการตรวจสอบกำลังรับแรงอัด
                        ของคอนกรีตด้วย Rebound Hammer Test ในตำแหน่งอื่นๆต่อไป

                        </div>

                    </div>
                </div>
            </div>


        )
    }
}

export default Hammer;