import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Pagination } from 'react-bootstrap';


class Hammer extends Component {
    
    render () {
        return (


            <div>
                <div className="row">
                    <div className="col-lg-8 col-sm-6">
                    <div className="p-4" style={{backgroundColor: "#f2f2f2"}}>
                        <Carousel>
                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/hammer00.png')} fluid/>                                
                            </Carousel.Item>

                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/hammer01.png')} fluid/> 
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">

                        <nav className="mt-3">
                            <ul className="pagination">

                                <Pagination.Prev className="mr-auto" href="visual" />
                                <h4 className="d-flex justify-content-center align-self-center" align="center">Rebound Hammer Test</h4>
                                <Pagination.Next className="ml-auto" href="pundit" />
                           
                            </ul>
                        </nav>
                        <hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>


                        <div className="mt-4 mr-4 mb-4 text-justify" style={{"lineHeight": "200%"}}>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        การตรวจสอบ Rebound Hammer Test เป็นการตรวจสอบหากำลังรับแรงอัดที่ผิวของคอนกรีตแบบไม่ทำลาย
                        (Non-destructive) ซึ่งวิธีนี้ไม่มีผลกระทบต่อเสถียรภาพของโครงสร้าง ตรวจสอบได้สะดวกและรวดเร็ว 
                        แม้ว่าค่าที่ได้จะเป็นเพียงค่าที่ใกล้เคียงกับค่าที่แท้จริงเท่านั้น แต่ก็เพียงพอที่จะนำผลที่ได้ไปใช้พิจารณาร่วมกับผลการทดสอบอื่นๆ
                        ประเมินค่าความแข็งแรงของโครงสร้างต่อไป

                        </div>

                        <div className="mt-3 mr-4 mb-5 text-justify" style={{"lineHeight": "200%"}}>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        เครื่องมือที่ใช้คือ Rebound Hammer ซึ่งใช้หลักการกระแทก และกระดอนกลับ (Rebound) ของสปริงหรือมวลยืดหยุ่น
                        กำลังที่กระดอนกลับ (Rebound) จะแปรผันกับค่าความแข็งแรงของผิวที่ทดสอบ

                        </div>

                    </div>
                </div>
            </div>


        )
    }
}

export default Hammer;