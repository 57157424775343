import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import "./Portfolio.css";


class Portfolio extends Component {
    
    render () {
        return (


            <div className="container margin-top mw-100 p-5 bg-white rounded fluid">

                <div className="row">

                    <div className="col-lg-12 mt-5">

                        <h3 className="mb-3">ผลงานการยกอาคาร</h3>
                        <hr className="mb-3" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>

                        <div className="row no-gutters">
                            <div className="col-lg-4 col-sm-4">

                                <div className="juxtapose">
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/bangyikan_after.png')} />  
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/bangyikan_before.png')} />  
                                </div>

                            </div>

                            <div className="col-lg-4 col-sm-4">

                                <div className="juxtapose">
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/boon_after.png')} />  
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/boon_before.png')} />  
                                </div>

                            </div>

                            <div className="col-lg-4 col-sm-4">
                                <div className="juxtapose">
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/klangkred_after.png')} />  
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/klangkred_before.png')} />  
                                </div>
                            </div>

                        </div>


                        <div className="row no-gutters">
                            <div className="col-lg-4 col-sm-4">

                                <div className="juxtapose">
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/koprayajeng_after.png')} fluid />  
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/koprayajeng_before.png')} fluid />  
                                </div>

                            </div>

                            <div className="col-lg-4 col-sm-4">

                                <div className="juxtapose">
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/maliwan_after.png')} fluid />  
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/maliwan_before.png')} fluid />  
                                </div>

                            </div>

                            <div className="col-lg-4 col-sm-4">
                                <div className="juxtapose">
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/boromhospital_after.png')} fluid />  
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/boromhospital_before.png')} fluid />  
                                </div>
                            </div>

                        </div>


                        <h3 className="mt-5 mb-3">ผลงานการย้ายอาคาร</h3>
                        <hr className="mb-3" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>

                        <div className="row no-gutters">
                            <div className="col-lg-4 col-sm-4">

                                <div className="juxtapose">
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/library_after.png')} fluid />  
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/library_before.png')} fluid />  
                                </div>

                            </div>

                            <div className="col-lg-4 col-sm-4">

                                <div className="juxtapose">
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/ruenmorporn_after.png')} fluid />  
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/ruenmorporn_before.png')} fluid />  
                                </div>

                            </div>

                            <div className="col-lg-4 col-sm-4">
                                <div className="juxtapose">
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/noble_after.png')} fluid />  
                                    <Image className="w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Slide/noble_before.png')} fluid />  
                                </div>
                            </div>

                        </div>


                        <h3 className="mt-5 mb-3">ผลงานอื่นๆ</h3>
                        <hr className="mb-3" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>

                        <div className="row no-gutters">

                            <div className="col-lg-4 col-md-4 col-sm-4 ov-container">

                                <Image className="w-100 ov-image" style={{"width": "100%"}} src={require('../../img/Resize/Others/rajini_testing.png')} fluid />  
                                
                                <div className="ov-overlay d-flex h-100">

                                    <div className="container headertext justify-content-center align-self-center" align="center" style={{"color": "white"}}>
                                        <div>งานตรวจสอบ</div>
                                        <div>โรงเรียนราชินีบน</div>
                                    </div>

                                </div>

                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-4 ov-container">

                                <Image className="w-100 ov-image" style={{"width": "100%"}} src={require('../../img/Resize/Others/wiparam_slab.png')} fluid />  
                                    
                                <div className="ov-overlay d-flex h-100">
                                    
                                    <div className="container headertext justify-content-center align-self-center" align="center" style={{"color": "white"}}>
                                        <div>Slab Load Test</div>
                                        <div>โรงพยาบาลวิภาราม นวมินทร์</div>
                                    </div>

                                </div>

                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-4 ov-container">

                                <Image className="w-100 ov-image" style={{"width": "100%"}} src={require('../../img/Resize/seismic00.png')} fluid />  
                                
                                <div className="ov-overlay d-flex h-100">

                                    <div className="container headertext justify-content-center align-self-center" align="center" style={{"color": "white"}}>
                                        Seismic Test พิกุลทองพลาสติก
                                    </div>
                                    
                                </div>

                            </div>

                        </div>


                        <div className="row no-gutters">

                            <div className="col-lg-4 col-md-4 col-sm-4 ov-container">

                                <Image className="w-100 ov-image" style={{"width": "100%"}} src={require('../../img/Resize/Others/underpinning_kamnerdwit.png')} fluid />  
                                
                                <div className="ov-overlay d-flex h-100">

                                    <div className="container headertext justify-content-center align-self-center" align="center" style={{"color": "white"}}>
                                        <div>งานเสริมเสาเข็ม</div>
                                        <div>โรงเรียนกำเนิดวิทย์</div>
                                    </div>

                                </div>

                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-4 ov-container">

                                <Image className="w-100 ov-image" style={{"width": "100%"}} src={require('../../img/Resize/Others/karnkeha_rust.png')} fluid />  
                                
                                <div className="ov-overlay d-flex h-100">
                                    <div className="container headertext justify-content-center align-self-center" align="center" style={{"color": "white"}}>
                                        <div>งานแก้ไขสนิมในเหล็กเสริม</div>
                                        <div>อาคารการเคหะหนองจอก</div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-4 col-sm-4 ov-container">

                                <Image className="w-100 ov-image" style={{"width": "100%"}} src={require('../../img/Resize/Others/cave_survey.png')} fluid />  
                                <div className="ov-overlay d-flex h-100">
                                    <div className="container headertext justify-content-center align-self-center" align="center" style={{"color": "white"}}>
                                        สำรวจพื้นที่ถ้ำหลวง จ.เชียงราย
                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>  


                </div>
            </div>


        )
    }
}

export default Portfolio;