import React, { Component } from 'react';
import { Image } from 'react-bootstrap';


class Liftandmove extends Component {
    
    render () {
        return (

            <div>
                <div className="row">
                    <div className="col-lg-8 col-sm-6">

                        <div className="row mb-2 no-gutters">
                            <div className="col ov-container">
                                <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Carousel/bangpakok_before.png')} fluid />
                                <div className="top-left">ก่อนยก</div>
                            </div>
                            <div className="col">
                                <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Carousel/bangpakok_after.png')} fluid />
                                <div className="top-left">หลังยก</div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col">
                                <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Carousel/library_before.png')} fluid />                         
                                <div className="top-left">ก่อนย้าย</div>
                            </div>
                            <div className="col">
                                <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Liftandmove/Carousel/library_after.png')} fluid />   
                                <div className="top-left">หลังย้าย</div>
                            </div>
                        </div>
          
                    </div>
                    <div className="col-lg-4 col-sm-6">

                        <h4 className="mt-4">ยกและย้ายอาคาร</h4>

                        <hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>


                        <div className="mt-4 mr-4 mb-3 text-justify" style={{"lineHeight": "200%"}}>

                        &nbsp;&nbsp;
                        งานยกและย้ายอาคาร  เป็นงานวิศวกรรมเฉพาะทาง มีการออกแบบและคำนวณตลอดจนกำหนดแนวทางในการยกและย้ายอาคารโดยเทคนิคพิเศษ
                        อาคารแต่ละหลังที่จะทำการยกและย้ายจะมีการคำนวณและออกแบบที่แตกต่างกันออกไป ทั้งนี้ขึ้นอยู่กับโครงสร้างของอาคาร เช่น
                        อาคารคอนกรีตเสริมเหล็ก อาคารโครงสร้างไม้ อาคารโครงสร้างเหล็ก หรือ อาคารโบราณที่เป็นอาคารประเภทผนังอิฐก่อรับแรงและไม่มีเหล็กเสริมภายในโครงสร้าง
                        เป็นต้น นอกจากพิจารณาโครงสร้างและฐานรากของอาคารแล้ว ยังต้องพิจารณาองค์ประกอบอื่นๆ เช่น ข้อมูลดินบริเวณที่ตั้งของอาคาร
                        ความเสื่อมสภาพของอาคาร และอื่นๆเป็นต้น
                    
                        </div>

                        <div className="mr-4 mb-5 text-justify" style={{"lineHeight": "200%"}}>

                        &nbsp;&nbsp;
                        ข้อดีของการยกและย้ายอาคาร คือ สามารถคงรูปแบบและสถาปัตยกรรมของอาคารเดิมไว้ได้โดยไม่ต้องทุบทำลาย 
                        ใช้เวลาในการปรับปรุงอาคารได้รวดเร็วกว่าการก่อสร้างใหม่ นอกจากนั้นเมื่อยกและย้ายอาคารแล้ว 
                        อาคารยังมีความมั่นคงแข็งแรงและใช้งานได้อย่างปลอดภัยเช่นเดิม
                    
                        </div>

                    </div>
                </div>
            </div>

        )
    }
}

export default Liftandmove;