import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Pagination } from 'react-bootstrap';


class Repair extends Component {
    
    render () {
        return (


            <div>
                <div className="row">
                    <div className="col-lg-8 col-sm-6">
                    <div className="p-4" style={{backgroundColor: "#f2f2f2"}}>
                        <Carousel>
                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/repair00.png')} fluid/>                                
                            </Carousel.Item>

                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/repair01.png')} />                                
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">

                        <nav className="mt-3">
                            <ul className="pagination">

                                <Pagination.Prev className="mr-auto" href="cfrp" />
                                <h4 className="d-flex justify-content-center align-self-center" align="center">แก้ไขโครงสร้าง<br></br>เนื่องจากเหล็กเสริมเป็นสนิม</h4>
                                <Pagination.Next className="ml-auto" href="underpinning" />
                           
                            </ul>
                        </nav>

                        <hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>


                        <div className="mt-4 mr-4 mb-5 text-justify" style={{"lineHeight": "180%"}}>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        ค้ำยันโครงสร้างที่ต้องการซ่อมแซมด้วยเหล็กรูปพรรณ สกัดคอนกรีตออก กรณีเหล็กเสริมเป็นสนิม 
                        เปลี่ยนเหล็กเสริมที่เสียหายออกและแทนที่ด้วยเหล็กขนาดเท่าเดิม หากไม่จำเป็นต้องเปลี่ยนเหล็กเสริม ต้องขัดสนิมออกให้หมด
                        หลังจากนั้นทาน้ำยาประสานคอนกรีต และน้ำยาป้องกันการเกิดสนิมใหม่ เข้าแบบตำแหน่งที่ต้องการซ่อมแซม และเทคอนกรีตกำลังสูงชนิดไม่หดตัว 
                        เก็บความเรียบร้อยด้วยซีเมนต์สำเร็จรูปผสมไฟเบอร์ และทาน้ำยาป้องกันการเกิดสนิม
                
                        </div>

                    </div>
                </div>
            </div>


        )
    }
}

export default Repair;