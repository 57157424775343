import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Pagination } from 'react-bootstrap';


class Settlement extends Component {
    
    render () {
        return (


            <div>
                <div className="row">
                    <div className="col-lg-8 col-sm-6">
                    <div className="p-4" style={{backgroundColor: "#f2f2f2"}}>
                        <Carousel>
                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/settlement00.png')} fluid/>                                
                            </Carousel.Item>

                            <Carousel.Item>
                            <Image className="d-block w-100" style={{"width": "100%"}} src={require('../../img/Resize/settlement01.png')} />                                
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">

                        <nav className="mt-3">
                            <ul className="pagination">

                                <Pagination.Prev className="mr-auto" href="crosshole" />
                                <h4 className="d-flex justify-content-center align-self-center" align="center">สำรวจการทรุดตัวของอาคาร (Settlement Survey)</h4>
                                <Pagination.Next className="ml-auto" href="visual" />
                           
                            </ul>
                        </nav>
                        <hr className="mb-5" style={{borderTop: "2px solid rgba(0, 0, 0, 0.1)"}}></hr>

                        <div className="mt-4 mr-4 text-justify" style={{"lineHeight": "220%"}}>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        การสำรวจค่าการทรุดตัว (Settlement Survey) เป็นการสำรวจพฤติกรรมความผิดปกติของฐานราก 
                        ทั้งนี้ฐานรากของอาคารหลังหนึ่งๆ จะมีค่าการทรุดตัวที่เท่ากัน หรือ ค่าการทรุดตัวที่ใกล้เคียงกันอยู่แล้ว
                        หากอาคารมีพฤติกรรมการทรุดตัวที่ไม่เท่ากัน ก็จะก่อให้เกิดความเสียหายต่อโครงสร้าง จึงต้องทำการสำรวจเก็บข้อมูลเพื่อนำไปวิเคราะห์
                        และหาแนวทางในการแก้ไขต่อไป

                        </div>

                    </div>
                </div>
            </div>


        )
    }
}

export default Settlement;