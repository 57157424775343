import React, { Component } from 'react';

import HomeCarousel from '../HomeCarousel/HomeCarousel';
import CompanyDetail from '../CompanyDetail/CompanyDetail';
import JobList from '../JobList/JobList';
import Portfolio from '../Portfolio/Portfolio';
import ContactFooter from '../ContactFooter/ContactFooter';


class Home extends Component {

  render () {

    return (
      <>
        <HomeCarousel/>
        <CompanyDetail/>
        <JobList/>
        <Portfolio/>
        <ContactFooter/>
      </>
    );

  }
}

export default Home;